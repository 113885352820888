<template>
  <RevButton
    data-qa="get-more-help"
    data-test="get-more-help"
    full-width="responsive"
    :to="conversationRoute"
    :variant="variant"
  >
    {{ i18n(translations.label) }}
  </RevButton>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'

import { PASTRAMI_CUSTOMER_CONVERSATION } from '@/scopes/customer-requests/routes'
import { CUSTOMER_CONVERSATION } from '~/scopes/customer-conversation/route-names'

import translations from './GetMoreHelpAction.translations'
import type { GetMoreHelpActionProps } from './GetMoreHelpAction.types'

const { resolutionId, variant } = withDefaults(
  defineProps<GetMoreHelpActionProps>(),
  {
    variant: 'secondary',
  },
)

const { FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY } =
  useRuntimeConfig().public

const { market } = useMarketplace()

const isCustomerConversationEnabled = (
  FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY as string
)?.includes(market.countryCode)

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const conversationRoute = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: isCustomerConversationEnabled
      ? CUSTOMER_CONVERSATION.RESOLUTION
      : PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION,
    params: {
      resolutionId,
    },
  }),
)
</script>
