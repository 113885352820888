<template>
  <RevButton
    v-if="!tiny"
    data-qa="print-return-label"
    data-test="print-return-label"
    full-width="responsive"
    target="_blank"
    :to="shipmentLabelUrl"
    :variant="variant"
  >
    {{ i18n(translations.label) }}
  </RevButton>

  <RevButtonTiny
    v-else
    class="w-fit"
    data-qa="print-return-label"
    data-test="print-return-label"
    :icon="IconDownload"
    target="_blank"
    :to="shipmentLabelUrl"
    :variant="variant"
  >
    {{ i18n(translations.label) }}
  </RevButtonTiny>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { IconDownload } from '@ds/icons/IconDownload'

import translations from './PrintLabelAction.translations'
import type { PrintLabelActionProps } from './PrintLabelAction.types'

const { shipmentLabelUrl, tiny, variant } = withDefaults(
  defineProps<PrintLabelActionProps>(),
  {
    tiny: false,
    variant: 'secondary',
  },
)

const i18n = useI18n()
</script>
