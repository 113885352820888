<template>
  <PrintLabelAction
    v-if="shipmentUrl"
    :shipment-label-url="shipmentUrl"
    :tiny="tinyActions"
    :variant="variant"
  />

  <ProofOfPurchaseAction
    v-if="hasProofOfPurchaseAction"
    :order-public-id="orderPublicId"
    :tiny="tinyActions"
    :variant="proofOfPurchaseActionVariant"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { RESOLUTION_OPTION_SERVICE_NAME_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import PrintLabelAction from '../PrintLabelAction/PrintLabelAction.vue'
import ProofOfPurchaseAction from '../ProofOfPurchaseAction/ProofOfPurchaseAction.vue'

import type { ReturnActionsProps } from './ReturnActions.types'

const { resolution, tinyActions, variant } = withDefaults(
  defineProps<ReturnActionsProps>(),
  {
    tinyActions: false,
    variant: 'primary',
  },
)

const orderPublicId = computed(
  () => resolution?.problemContext?.orderline?.publicId,
)

const shipmentUrl = computed(
  () => resolution?.resolutionRequest?.shipment?.labelUrl,
)

const hasProofOfPurchaseAction = computed(
  () =>
    resolution?.resolutionOption?.serviceName !==
    RESOLUTION_OPTION_SERVICE_NAME_ENUM.backRepair,
)

const proofOfPurchaseActionVariant = computed(() =>
  !isEmpty(shipmentUrl.value) ? 'secondary' : variant,
)
</script>
