<template>
  <div>
    <RevTextList variant="ordered">
      <template #default>
        <RevTextListItem class="flex">
          <div>
            {{ i18n(translations.printSetp) }}
          </div>
        </RevTextListItem>

        <RevTextListItem class="flex">
          <div class="flex flex-col space-y-16">
            <span>
              {{ i18n(translations.packingStepContent1) }}
            </span>

            <span>
              {{ i18n(translations.packingStepContent2) }}
            </span>
          </div>
        </RevTextListItem>

        <RevTextListItem class="flex">
          <div>
            {{ i18n(translations.stickStep) }}
          </div>
        </RevTextListItem>

        <RevTextListItem class="flex">
          <div class="flex flex-col space-y-8">
            {{ i18n(translations.sendStep, { date: i18n.date(expiryDate) }) }}

            <RevLink
              class="mt-8"
              full-width="responsive"
              target="_blank"
              :to="productReturnFaqArticleLink"
            >
              {{ i18n(translations.sendStepCta) }}
            </RevLink>
          </div>
        </RevTextListItem>

        <RevTextListItem class="flex">
          <div>
            {{ i18n(translations.jobDoneStep) }}
          </div>
        </RevTextListItem>
      </template>
    </RevTextList>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevLink } from '@ds/components/Link'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'

import translations from './ReturnSteps.translations'
import type { ReturnStepsProps } from './ReturnSteps.types'

const { expiryDate } = defineProps<ReturnStepsProps>()

const i18n = useI18n()

const { ZENDESK_PRODUCT_RETURN_FAQ_ARTICLE } = useRuntimeConfig().public
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const productReturnFaqArticleLink = toNuxtLink({
  type: 'external',
  href: `${zendeskFaqBaseUrl}/articles/${ZENDESK_PRODUCT_RETURN_FAQ_ARTICLE}#return-label`,
})
</script>
