export default {
  expectedDuration: {
    id: 'resolution_flow_return_confirmation_page_description_expected_durations',
    defaultMessage: `{duration, number} {duration, plural,
              one {business day}
              other {business days}
            }`,
  },
  heading: {
    id: 'resolution_flow_return_confirmation_page_title_repair',
    defaultMessage: 'All set for your repair!',
  },
  pageTitle: {
    id: 'resolution_flow_return_confirmation_page_breadcrumb_repair_request',
    defaultMessage: 'Repair request',
  },

  subHeading: {
    id: 'resolution_flow_return_confirmation_page_info_title_repair_requested',
    defaultMessage: 'Repair requested',
  },

  description: {
    id: 'resolution_flow_return_confirmation_page_description_repair_option',
    defaultMessage:
      'Once your item is received, it will be repaired by our repair partner within 3 business days and then sent back to you. It may take a few more days to arrive.',
  },
}
